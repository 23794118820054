<template>
  <div>
    <b-card title="Upload JSON file">
      <b-form-file
        accept=".json"
        @change="handleFileChange()"
      />
      <!-- <b-progress
        v-if="progress !== null"
        :value="progress"
        max="100"
        class="mt-3"
      /> -->
      <div v-if="error">
        <b-card
          title="Validation Errors"
          class="mt-3"
        >
          <ul>
            <li
              v-for="(err, index) in error"
              :key="index"
            >
              <strong>{{ err.instancePath }}</strong>: {{ err.message }}
              <br>
              <em>(Schema: {{ err.schemaName }}, Expected: {{ err.params.type }})</em>
            </li>
          </ul>
        </b-card>
      </div>
      <div v-if="valid">
        File is valid!
      </div>
    </b-card></div></template>

<script>
// eslint-disable-next-line import/no-webpack-loader-syntax, import/extensions
import Worker from 'worker-loader!./worker.js'

export default {
  data() {
    return {
      error: null,
      valid: null,
    }
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = () => {
        const fileData = JSON.parse(reader.result)

        const worker = new Worker()
        worker.postMessage({ data: fileData })
        worker.onmessage = e => {
          if (e.data.error) {
            // Check if errors are in array format and set error state accordingly
            if (Array.isArray(e.data.error)) {
              this.error = e.data.error.map(err => ({
                instancePath: err.instancePath || 'Unknown Path',
                message: err.message || 'Unknown Error',
                schemaName: err.schemaName || 'Unknown Schema',
                params: err.params || {},
              }))
            } else {
              this.error = [{ message: e.data.error }]
            }
            this.valid = false
          } else if (e.data.valid) {
            this.error = null
            this.valid = true
          }
        }
      }
      reader.readAsText(file)
    },
  },

}
</script>
