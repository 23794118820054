<template>
  <div>
    <!-- table -->
    <data-table
      :columns="columns"
      requesturl="/barcodes"
      :server-params-modifier="serverParamsModifier"
    >

      <!-- Column: Action -->
      <template #actions="{ rowData }">
        <span>
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              v-if="rowData.kitType==='kid'"
              @click="$router.push(`/barcode/kid/${rowData._id}/report/upload`)"
            >
              <feather-icon
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <span>Upload Report</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
              v-if="rowData.kitType==='kid'"
              :disabled="(rowData.status !== 'Data analysis completed' && rowData.status !== 'Reports Ready') || rowData.questionnaireStatus !== 'Survey Completed'"
              @click="$router.push(`/barcode/kid/${rowData._id}/report/view`)"
            >
              <feather-icon
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <span>View & Publish Reports</span>
            </b-dropdown-item> -->
            <b-dropdown-item
              v-if="rowData.kitType==='adult'"
              @click="$router.push({ name: 'ReportTimeline', params: { id: rowData._id } })"
            >
              <feather-icon
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <span>Upload Report</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
              v-if="rowData.kitType==='adult'"
              :disabled="(rowData.status !== 'Data analysis completed' && rowData.status !== 'Reports Ready') || rowData.questionnaireStatus !== 'Survey Completed'"
              @click="$router.push(`/barcode/adult/${rowData._id}/report/view`)"
            >
              <feather-icon
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <span>View & Publish Reports</span>
            </b-dropdown-item> -->
            <b-dropdown-item
              v-if="rowData.kitType==='longevity'"
              @click="$router.push(`/barcode/longevity/${rowData._id}/report/upload`)"
            >
              <feather-icon
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <span>Upload Report</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
              v-if="rowData.kitType==='longevity'"
              :disabled="(rowData.status !== 'Data analysis completed' && rowData.status !== 'Reports Ready') || rowData.questionnaireStatus !== 'Survey Completed'"
              @click="$router.push(`/barcode/longevity/${rowData._id}/report/view`)"
            >
              <feather-icon
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <span>View & Publish Reports</span>
            </b-dropdown-item> -->
            <b-dropdown-item
              v-if="rowData.kitType==='covid'"
              @click="$router.push(`/barcode/covid/${rowData._id}/report/upload`)"
            >
              <feather-icon
                icon="EyeIcon"
                class="mr-50"
              />
              <span>Upload Covid Reports</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
              v-if="rowData.kitType==='covid'"
              :disabled="(rowData.status !== 'Data analysis completed' && rowData.status !== 'Reports Ready') || rowData.questionnaireStatus !== 'Survey Completed'"
              @click="$router.push(`/barcode/covid/${rowData._id}/report/view`)"
            >
              <feather-icon
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <span>View & Publish Covid Reports</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </span>
      </template>
    </data-table>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import DataTable from '@/components/DataTable.vue'

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      columns: [
        {
          label: 'DU Barcode',
          field: 'duBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'ST Barcode',
          field: 'stBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'SA Barcode',
          field: 'saBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'COR Barcode',
          field: 'corBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Previous Barcodes',
          field: 'previousBarcodes',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
          formatFn: previousBarcodes => previousBarcodes.join(' <br>'),
        },
        {
          label: 'isFollowUp',
          field: 'followUp',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
          formatFn: followUp => (followUp === true ? 'yes' : 'no'),
        },
        {
          label: 'Kit type',
          field: 'kitType',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
          formatFn: kitType => kitType.replace(/^\w/, c => c.toUpperCase()),
        },
        {
          label: 'Data Status',
          field: 'dataStatus',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'PDF Status',
          field: 'pdfStatus',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Account ID',
          field: 'accountID',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Region',
          field: 'country',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Kit User',
          field: 'kitUser',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      serverParams: {
        columnFilters: {
          kitUser: { $ne: '' }, isObsolete: false, status: 'Data analysis completed',
        },
        page: 1,
        perPage: 20,
      },
      selectedBarcode: null,
      selectedBarcodeType: null,
      duBarcode: null,
      saBarcode: null,
      stBarcode: null,
      corBarcode: null,
    }
  },
  methods: {
    serverParamsModifier(serverParams) {
      const serverParamsClone = JSON.parse(JSON.stringify(serverParams))
      serverParamsClone.columnFilters.kitUser = { $ne: '' }
      serverParamsClone.columnFilters.isObsolete = false
      serverParamsClone.columnFilters.status = 'Data analysis completed'
      return serverParamsClone
    },
    handleRowClick(rowData) {
      // console.log(rowData._id)
      this.$router.push({ name: 'ReportsTimeline', params: { id: rowData._id } })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
