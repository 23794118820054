<template>
  <b-card>

    <!-- Custom title with "here" link beside it -->
    <template #header>
      <div class="d-flex justify-content-between align-items-center">
        <span>View Data for {{ barcode }}</span>
        <b-button
          variant="link"
          class=""
          @click="reviewJson"
        >
          here
        </b-button>
      </div>
    </template>

    <!-- Comments Section -->
    <b-form-group>
      <b-form-textarea
        id="comments"
        v-model="comments"
        rows="3"
        placeholder="Enter your comments here..."
      />
    </b-form-group>

    <!-- Publish and Reject Buttons -->
    <b-button
      variant="success"
      class="mr-2"
      @click="publishData"
    >
      Publish
    </b-button>
    <b-button
      variant="danger"
      @click="rejectData"
    >
      Reject
    </b-button>
    <!-- JSON Data Modal -->
    <b-modal
      id="json-data-modal"
      v-model="isModalVisible"
      title="JSON Data"
      size="lg"
      hide-footer
      body-class="modal-body-scroll"
    >
      <json-viewer :value="jsonData" />
    </b-modal>
  </b-card>
</template>

<script>
import { BButton, BFormGroup, BFormTextarea } from 'bootstrap-vue'
import JsonViewer from 'vue-json-viewer'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormTextarea,
    JsonViewer,
  },
  data() {
    return {
      barcode: 'xxxx', // Replace with actual barcode data
      jsonData: null, // Initialize as null for storing fetched JSON data
      comments: '', // Initialize comments as an empty string
      isModalVisible: false, // Control the visibility of the modal
    }
  },
  methods: {
    // Fetch JSON data from an API endpoint
    async fetchData() {
      try {
        // Example JSON data with nested structure
        const data = {
          biological_age_score: {
            lifestyle_score: [
              {
                body_shape: 0.0,
                physical_activity: 1.0,
                stress_level: 2.8,
                consumes_fresh_fruits: 0.0,
                consumes_fresh_vegetables: 0.0,
                alcohol: -1.5,
                smoking: 0.0,
                bmi_score: 1.0,
                lifestyle_score: 3.3,
              },
            ],
            ba: [
              {
                chronological_age: 51.0,
                mb_score: 81.02,
                mb_ba: 132.02,
                host_score: -0.34,
                host_ba: 50.66,
                du_score: 54.78,
                du_ba: 105.78,
                lifestyle_score: 3.3,
                comorbidities_score: 0,
                du_lifestyle_comorb_ba: 109.08,
                du_lifestyle_comorb_ba_thresold: 61.0,
                db: 'v2.1',
              },
            ],
            lifestyle_comorbidities: [],
          },
          dual_inference: [
            {
              nhcid: 'NHC0000002',
              conditions: 'Constipation',
              name_in_report: 'Constipation',
              parent_id_for_mb: 'Not Applicable',
              in_report: true,
              order_in_report: 'DGH1',
              section: 'Health risks',
              categories: 'Digestive Health',
              sub_category: '',
              nongenetic: 'No',
              gender: 'All',
              age_lower: 3,
              age_upper: 100,
              product_version: 'v1.0',
              host_prs: null,
              host_rank: null,
              host_category: '',
              host_relative_rank: '',
              host_wt: null,
              host_small_inference: '',
              host_long_inference: '',
              host_nutritional_recommendation: '',
              host_exercise_recommendation: [],
              mb_prs: 9.0,
              mb_rank: 97.87,
              mb_category: 'High',
              mb_relative_rank: 'Higher than 96.87% of people in our reference dataset',
              mb_wt: 4.0,
              mb_small_inference: 'High',
              mb_long_inference: 'Risk is significantly greater than the population average',
              mb_nutritional_recommendation: 'Recommendation_table',
              mb_exercise_recommendation: [],
              dual_wt_rank: null,
              dual_wt_category: '',
              final_rank: 97.87,
              final_category: 'High',
              relative_rank: 'Higher than 96.87% of people in our reference dataset',
              small_inference: 'High',
              long_inference: 'Risk is significantly greater than the population average',
              nutritional_recommendation: 'Recommendation_table',
              exercise_recommendation: [],
              db: 'v2.1',
              long_inf_summary: '',
            },
            {
              nhcid: 'NHC0000003',
              conditions: "Crohn's disease",
              name_in_report: "Crohn's disease",
              parent_id_for_mb: 'Not Applicable',
              in_report: true,
              order_in_report: 'DGH2',
              section: 'Health risks',
              categories: 'Digestive Health',
              sub_category: '',
              nongenetic: 'No',
              gender: 'All',
              age_lower: 3,
              age_upper: 100,
              product_version: 'v1.0',
              host_prs: 18.9087291,
              host_rank: 86.53,
              host_category: 'Slightly High',
              host_relative_rank: 'Higher than 85.53% of people in our reference dataset',
              host_wt: 3.0,
              host_small_inference: 'Slightly High',
              host_long_inference: 'Risk is above the population average',
              host_nutritional_recommendation: 'Recommendation_table',
              host_exercise_recommendation: [],
              mb_prs: 7.0,
              mb_rank: 99.97,
              mb_category: 'High',
              mb_relative_rank: 'Higher than 98.97% of people in our reference dataset',
              mb_wt: 4.0,
              mb_small_inference: 'High',
              mb_long_inference: 'Risk is significantly greater than the population average',
              mb_nutritional_recommendation: 'Recommendation_table',
              mb_exercise_recommendation: [],
              dual_wt_rank: 94.21,
              dual_wt_category: 'High',
              final_rank: 94.21,
              final_category: 'High',
              relative_rank: 'Higher than 93.21% of people in our reference dataset',
              small_inference: 'High',
              long_inference: 'Risk is significantly greater than the population average',
              nutritional_recommendation: 'Recommendation_table',
              exercise_recommendation: [],
              db: 'v2.1',
              long_inf_summary: '',
            },
          ],
        }
        this.jsonData = data
      } catch (error) {
      //  console.error('Error fetching JSON:', error)
      }
    },

    // Review button clicked: fetch and display JSON data
    async reviewJson() {
      await this.fetchData() // Wait for the JSON data to be fetched first

      // Ensure data is fetched
      if (this.jsonData) {
        this.isModalVisible = true // Show the modal
      } else {
        this.$bvToast.toast('No data fetched yet. Please try again.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },

    // Publish data with comments
    publishData() {
      // console.log('Publishing data with comments:', this.comments)
      // Add your publish logic here
    },

    // Reject data with comments
    rejectData() {
      // console.log('Rejecting data with comments:', this.comments)
      // Add your reject logic here
    },
  },
}
</script>

<style>
.modal-body-scroll {
  max-height: 90vh;
  overflow-y: auto;
}
</style>
